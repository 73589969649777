<template>
  <div id="kt_header" style="" class="Header header align-items-stretch">
    <!--begin::Container-->
    <div class="container-fluid d-flex align-items-stretch justify-content-between">
      <!--begin::Aside mobile toggle-->
      <div class="d-flex align-items-center d-lg-none ms-n3 me-1" title="Show aside menu">
        <div class="btn btn-icon btn-active-color-white" id="kt_aside_mobile_toggle">
          <i class="bi bi-list fs-1"></i>
        </div>
      </div>
      <!--end::Aside mobile toggle-->
      <!--begin::Mobile logo-->
      <div class="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
        <a href="#" class="d-lg-none">
          <!-- <img alt="Logo" :src="logoImgSrc" class="h-15px" />-->
        </a>
      </div>
      <!--end::Mobile logo-->
      <!--begin::Wrapper-->
      <div class="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
        <!--begin::Navbar-->
        <div class="d-flex align-items-stretch" id="kt_header_nav">
          <!--begin::Menu wrapper-->
          <div class="header-menu align-items-stretch" data-kt-drawer="true" data-kt-drawer-name="header-menu" data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true" data-kt-drawer-width="{default:'200px', '300px': '250px'}" data-kt-drawer-direction="end" data-kt-drawer-toggle="#kt_header_menu_mobile_toggle" data-kt-place="true" data-kt-place-mode="prepend" data-kt-place-parent="{default: '#kt_body', lg: '#kt_header_nav'}">
            <!--begin::Menu-->
            <div class="menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch" id="#kt_header_menu" data-kt-menu="true">
            </div>
            <!--end::Menu-->
          </div>
          <!--end::Menu wrapper-->
        </div>
        <!--end::Navbar-->
        <!--begin::Topbar-->
        <div class="d-flex align-items-stretch flex-shrink-0">
          <!--begin::Toolbar wrapper-->
          <div class="topbar d-flex align-items-stretch flex-shrink-0">
            <!--start::sign-out -->
            <div class="d-flex align-items-stretch">
              <div class="topbar-item position-relative px-3 px-lg-5">
                <div class="fw-bolder d-flex align-items-center fs-5 text-white">{{row.full_name}}</div>
              </div>
            </div>
            <!--end::sign-out -->
            <!--start::sign-out -->
            <div class="d-flex align-items-stretch">
              <!--begin::Menu wrapper-->
              <a class="topbar-item position-relative px-3 px-lg-5" :href="url">
                <i class="bi bi-box-arrow-in-left fs-3"></i>
              </a>
              <!--end::Menu wrapper-->
            </div>
            <!--end::sign-out -->

          </div>
          <!--end::Toolbar wrapper-->
        </div>
        <!--end::Topbar-->
      </div>
      <!--end::Wrapper-->
    </div>
    <!--end::Container-->
  </div>

</template>

<script>
    import ApiServices from '../core/services/api.services';
    import bgImg from "../assets/media/misc/pattern-1.jpg";
    import logoImg from "../assets/media/logos/logo-compact.svg";

    export default {
        name: 'Header',
        data() {
            return {
                bgSrc : bgImg ,
                logoImgSrc : logoImg ,
                usrSrc : '',
                toggleHeader : false ,
                toggleNotification : false ,
                row: { name:'', email:''},
                url: '',

            }
        },
        methods:{
            logOut() {
                this.$store.dispatch('authentication/logout');
                this.$router.push('/login');
            },
            addClassTohdr(){
                if(this.toggleNotification){
                    this.toggleHeader = true;
                }else{
                    this.toggleHeader = !this.toggleHeader
                }
                this.toggleNotification = false ;
            },
            addClassToNoti(){
                if(this.toggleHeader){
                    this.toggleNotification = true;
                }else{
                    this.toggleNotification = !this.toggleNotification
                }
                this.toggleHeader = false ;
            },
            mouseLeave: function() {
                this.toggleHeader = false ;
                this.toggleNotification = false ;
            }
        }
        ,
        created: function(){
            const user = localStorage.getItem('user');
            if (!user) {
                this.$router.push('/login');
            }else {
                this.row = JSON.parse(user);
            }
            this.url = ApiServices.getMainCpPath()+'logout.php';

            // console.log(this.row );
            // console.log(this.row.full_name);

            /* ApiServices.get('Admin/template/header').then(
                    response => {
                      if(response.data){
                         this.row = response.data ;
                      }
                    },
                    error => {
                      this.$toaster.error(error);
                    });
            */
        }
    }
</script>
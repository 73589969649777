import config from './config';
import ApiServices from './api.services';
import axios from 'axios';
export const userService = { login, logout ,handleResponse};

function login(id_number) {
    axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
    // axios.defaults.headers.common['Access-Control-Allow-Methods'] = 'POST, GET, OPTIONS, PUT, DELETE';
    // axios.defaults.headers.common['Access-Control-Allow-Headers'] = 'Content-Type, X-Auth-Token, Origin, Authorization';


    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id_number })
    };
    // return fetch(`${config.apiUrl}authenticate`, requestOptions)
    //     .then(handleResponse)
    //     .then(user => {
    //         // login successful if there's a jwt token in the response
    //         if (user.token) {
    //             // store user details and jwt token in local storage to keep user logged in between page refreshes
    //             resolve({ ok: true, text: () => Promise.resolve(JSON.stringify(responseJson)) });
    //             localStorage.setItem('user', JSON.stringify(user));
    //         }
    //
    //         return user;
    //     });

    return axios.post(ApiServices.getLoginURLPath(), {
    // return axios.post(`${apiUrl}authenticate`, {
        id_number: id_number,
    })
        .then(response => {
            if (response.data.response.token) {
                localStorage.setItem('user', JSON.stringify(response.data.response.user));
                localStorage.setItem('token', JSON.stringify(response.data.response.token));

            }
    return response.data;
});
}

function logout() {

    //call logout function ...
var params  ='';
    ApiServices.logout(params);
    // remove user from local storage to log user out
    localStorage.removeItem('user');
    localStorage.removeItem('token');
}

 export function handleResponse(response) {
  

    if (!response.ok) {
        if (response.status === 401) {
            // auto logout if 401 response returned from api
            // logout();
            // window.location.href='/login';

            // location.reload(true);
        }

        const error = response.statusText;
        return Promise.reject(error);
    }
    return response.statusText;

 
}
import Vue from "vue";
import Router from "vue-router";

import HomePage from "./views/HomePage.vue";
import LoginPage from "./views/auth/LoginPage.vue";
import Dashboard from "./views/Dashboard.vue";

Vue.use(Router);

export const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      component: HomePage,
      children: [
        { path: "/", redirect: "/home" },
        { path: "/home", name: "Home", component: Dashboard },

        // requests
        {
          path: "/requests",
          name: "requests",
          component: () => import("./views/pages/requests/main.vue"),
          children: [
            { path: "/requests", redirect: "/requests" },
            { path: "list", name: "requests-list", component: () => import("./views/pages/requests/list.vue")},
            { path: "transactions", name: "requests-transactions", component: () => import("./views/pages/requests/transactions.vue")},
          ],
        },

        // suggestions
        {
          path: "/suggestions",
          name: "suggestions",
          component: () => import("./views/pages/suggestions.vue"),
        },
        // complaints
        {
          path: "/complaints",
          name: "complaints",
          component: () => import("./views/pages/complaints.vue"),
        },

         // employees
         {
          path: "/employees",
          name: "employees-management",
          component: () => import("./views/pages/employees/employees.vue"),
        },
        // constants
        {
          path: "/constants",
          name: "constants",
          component: () => import("./views/pages/constants/main.vue"),
          children: [
            { path: "/constants", redirect: "/constants" },
            {
              path: "areas",
              name: "areas",
              component: () =>
                  import("./views/pages/constants/areas.vue"),
            },
            // services
            {
              path: "services-list",
              name: "services-list",
              component: () => import("./views/pages/constants/services/list.vue"),
            },
            {
              path: "services-form/:id",
              name: "services-form",
              component: () => import("./views/pages/constants/services/form.vue"),
            },
            {
              path: "service-categories",
              name: "service-categories",
              component: () => import("./views/pages/constants/services/categories.vue"),
            },
          ],
        },

        // user account , settings
        {
          path: "/Profile",
          name: "Profile",
          component: () => import("./views/pages/users/Profile.vue"),
        },
        {
          path: "/AccountSettings",
          name: "AccountSettings",
          component: () => import("./views/pages/users/AccountSettings.vue"),
        },
        {
          path: "/Users",
          name: "Users",
          component: () => import("./views/pages/users/list.vue"),
        },
        {
          path: "/SmsSettings",
          name: "SmsSettings",
          component: () => import("./views/pages/SmsSettings.vue"),
        },
        {
          path: "/Settings",
          name: "Settings",
          component: () => import("./views/pages/Settings.vue"),
        },
      ],
    },
    { path: "/login", component: LoginPage },

    // otherwise redirect to home
    { path: "*", redirect: "/" },
  ],
});

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login','/reset-password'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');

  if (authRequired && !loggedIn) {
    return next('/login');
  }

  next();
})

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "Dashboard content d-flex flex-column flex-column-fluid",
      attrs: { id: "kt_content" }
    },
    [
      _c("div", { staticClass: "toolbar", attrs: { id: "kt_toolbar" } }, [
        _c(
          "div",
          {
            staticClass: "container-fluid d-flex flex-stack",
            attrs: { id: "kt_toolbar_container" }
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "\n            page-title\n            d-flex\n            align-items-center\n            me-3\n            flex-wrap\n            mb-5 mb-lg-0\n            lh-1\n          ",
                attrs: {
                  "data-kt-place": "true",
                  "data-kt-place-mode": "prepend",
                  "data-kt-place-parent":
                    "{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
                }
              },
              [
                _c(
                  "ul",
                  {
                    staticClass:
                      "breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1"
                  },
                  [
                    _c(
                      "li",
                      { staticClass: "breadcrumb-item text-muted" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "text-success fw-bolder",
                            attrs: { to: "/" }
                          },
                          [
                            _c("span", { staticClass: "fa fa-home" }),
                            _vm._v("\n                الرئيسية\n              ")
                          ]
                        )
                      ],
                      1
                    )
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "d-flex align-items-center py-1" })
          ]
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "post d-flex flex-column-fluid",
          attrs: { id: "kt_post" }
        },
        [
          _c(
            "div",
            { staticClass: "container", attrs: { id: "kt_content_container" } },
            [
              _c("div", { staticClass: "card card-xxl-stretch" }, [
                _vm._m(0),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "card-body p-0" },
                  [
                    _c(
                      "div",
                      { staticClass: "card-p mt-n20 position-relative" },
                      [
                        _c("div", { staticClass: "row g-0" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "\n                    col-sm\n                    bg-light-warning\n                    px-6\n                    py-8\n                    rounded-4\n                    me-7\n                    mb-7\n                    d-flex\n                    flex-column\n                    corner\n                  "
                            },
                            [
                              _vm._m(1),
                              _vm._v(" "),
                              _vm._m(2),
                              _vm._v(" "),
                              _c(
                                "h3",
                                {
                                  staticClass:
                                    "txt-color-gray fw-bold fs-6 mt-2 text-center text-warning"
                                },
                                [_c("b", [_vm._v(_vm._s(_vm.row.requests))])]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "\n                    col-sm\n                    bg-light-success\n                    px-6\n                    py-8\n                    rounded-4\n                    me-7\n                    mb-7\n                    d-flex\n                    flex-column\n                    corner\n                  "
                            },
                            [
                              _vm._m(3),
                              _vm._v(" "),
                              _vm._m(4),
                              _vm._v(" "),
                              _c(
                                "h3",
                                {
                                  staticClass:
                                    "txt-color-gray fw-bold fs-6 mt-2 text-success text-center"
                                },
                                [
                                  _c("b", [
                                    _vm._v(_vm._s(_vm.row.transactions))
                                  ])
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "\n                    col-sm\n                    bg-light-danger\n                     me-4 px-6\n                    py-8\n                    rounded-4\n                    me-7\n                    mb-7\n                    d-flex\n                    flex-column\n                    corner\n                  "
                            },
                            [
                              _vm._m(5),
                              _vm._v(" "),
                              _vm._m(6),
                              _vm._v(" "),
                              _c(
                                "h3",
                                {
                                  staticClass:
                                    "txt-color-gray fw-bold fs-6 mt-2 text-center text-danger"
                                },
                                [_c("b", [_vm._v(_vm._s(_vm.row.suggestions))])]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "\n                    col-sm\n                     bg-light-success\n                    px-6\n                    py-8\n                    rounded-4\n                    me-7\n                    mb-7\n                    d-flex\n                    flex-column\n                    corner\n                  "
                            },
                            [
                              _vm._m(7),
                              _vm._v(" "),
                              _vm._m(8),
                              _vm._v(" "),
                              _c(
                                "h3",
                                {
                                  staticClass:
                                    "txt-color-gray fw-bold fs-6 mt-2 text-center text-success"
                                },
                                [_c("b", [_vm._v(_vm._s(_vm.row.complaints))])]
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "row g-0" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-sm rounded-4 me-7 mb-7 d-flex flex-column corner"
                            },
                            [
                              _c("div", { staticClass: "card mb-5 mb-xl-10" }, [
                                _c("div", { staticClass: "row gy-5 g-xl-10" }, [
                                  _c("div", { staticClass: "col-xl-12" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "card-header border-0 pt-5"
                                      },
                                      [
                                        _c(
                                          "h3",
                                          {
                                            staticClass:
                                              "card-title align-items-start flex-column"
                                          },
                                          [
                                            _c("router-link", {
                                              staticClass:
                                                "card-label fw-bolder fs-3 mb-1 text-success",
                                              attrs: {
                                                to: "/requests/list",
                                                custom: ""
                                              },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var href = ref.href
                                                    var route = ref.route
                                                    var navigate = ref.navigate
                                                    var isActive = ref.isActive
                                                    return [
                                                      _c(
                                                        "span",
                                                        {
                                                          attrs: {
                                                            active: isActive,
                                                            href: href,
                                                            role: "link"
                                                          },
                                                          on: {
                                                            click: navigate,
                                                            keypress: function(
                                                              $event
                                                            ) {
                                                              if (
                                                                !$event.type.indexOf(
                                                                  "key"
                                                                ) &&
                                                                _vm._k(
                                                                  $event.keyCode,
                                                                  "enter",
                                                                  13,
                                                                  $event.key,
                                                                  "Enter"
                                                                )
                                                              ) {
                                                                return null
                                                              }
                                                              return navigate(
                                                                $event
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c("span", {
                                                            staticClass:
                                                              "fas fa-wallet "
                                                          }),
                                                          _vm._v(" "),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "ml-2 me-2"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "طلبات المعاملات النشطة"
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ])
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "text-muted mt-1 fw-bold fs-7"
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    " اجمالي عدد طلبات المعاملات النشطة : "
                                                  )
                                                ]),
                                                _vm._v(" "),
                                                _c("span", [
                                                  _vm._v(
                                                    " ( " +
                                                      _vm._s(
                                                        _vm.row.active_requests
                                                      ) +
                                                      " ) "
                                                  )
                                                ]),
                                                _vm._v(" "),
                                                _c("span", [_vm._v(" طلب ")])
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm.row.list.length > 0
                                      ? _c(
                                          "div",
                                          { staticClass: "card-body" },
                                          [
                                            _vm.row.list.length == 0
                                              ? _c(
                                                  "div",
                                                  { staticClass: "row mb-5" },
                                                  [_vm._m(9)]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "table-responsive"
                                              },
                                              [
                                                _c(
                                                  "table",
                                                  {
                                                    staticClass:
                                                      "\n                            table table-row-dashed table-row-gray-300\n                            align-middle\n                            gs-0\n                            gy-4\n                          "
                                                  },
                                                  [
                                                    _vm._m(10),
                                                    _vm._v(" "),
                                                    _c(
                                                      "tbody",
                                                      _vm._l(
                                                        _vm.row.list,
                                                        function(x, index) {
                                                          return _c(
                                                            "tr",
                                                            { key: index },
                                                            [
                                                              _c(
                                                                "td",
                                                                {
                                                                  staticClass:
                                                                    "text-center"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      index + 1
                                                                    )
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "td",
                                                                {
                                                                  staticClass:
                                                                    "text-center"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      x.request_no
                                                                    )
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "td",
                                                                {
                                                                  staticClass:
                                                                    "text-center"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      x.applicant_name
                                                                    )
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "td",
                                                                {
                                                                  staticClass:
                                                                    "text-center"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      x.applicant_id_no
                                                                    )
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "td",
                                                                {
                                                                  staticClass:
                                                                    "text-center"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      x
                                                                        .service_category
                                                                        .name
                                                                    )
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "td",
                                                                {
                                                                  staticClass:
                                                                    "text-center"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      x.service
                                                                        .name
                                                                    )
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "td",
                                                                {
                                                                  staticClass:
                                                                    "text-center"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      x.date
                                                                    )
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "td",
                                                                {
                                                                  staticClass:
                                                                    "text-center"
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "btn-group",
                                                                      staticStyle: {
                                                                        "margin-left":
                                                                          "10px !important"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "button",
                                                                        {
                                                                          directives: [
                                                                            {
                                                                              name:
                                                                                "b-tooltip",
                                                                              rawName:
                                                                                "v-b-tooltip.hover",
                                                                              modifiers: {
                                                                                hover: true
                                                                              }
                                                                            }
                                                                          ],
                                                                          staticClass:
                                                                            "btn btn-sm btn-success",
                                                                          attrs: {
                                                                            type:
                                                                              "button",
                                                                            title:
                                                                              "عرض الطلب"
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.showRaw(
                                                                                index,
                                                                                x
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "fa fa-eye"
                                                                            }
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      x.status_id ==
                                                                      1
                                                                        ? _c(
                                                                            "button",
                                                                            {
                                                                              directives: [
                                                                                {
                                                                                  name:
                                                                                    "b-tooltip",
                                                                                  rawName:
                                                                                    "v-b-tooltip.hover",
                                                                                  modifiers: {
                                                                                    hover: true
                                                                                  }
                                                                                }
                                                                              ],
                                                                              staticClass:
                                                                                "btn btn-sm btn-warning",
                                                                              attrs: {
                                                                                type:
                                                                                  "button",
                                                                                title:
                                                                                  "انهاء الطلب"
                                                                              },
                                                                              on: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.setStatus(
                                                                                    index,
                                                                                    x,
                                                                                    3
                                                                                  )
                                                                                }
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "fa fa-hourglass-end"
                                                                                }
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      x.status_id ==
                                                                      1
                                                                        ? _c(
                                                                            "button",
                                                                            {
                                                                              directives: [
                                                                                {
                                                                                  name:
                                                                                    "b-tooltip",
                                                                                  rawName:
                                                                                    "v-b-tooltip.hover",
                                                                                  modifiers: {
                                                                                    hover: true
                                                                                  }
                                                                                }
                                                                              ],
                                                                              staticClass:
                                                                                "btn btn-sm",
                                                                              staticStyle: {
                                                                                background:
                                                                                  "#a9a9a9",
                                                                                color:
                                                                                  "white"
                                                                              },
                                                                              attrs: {
                                                                                type:
                                                                                  "button",
                                                                                title:
                                                                                  "تعليق الطلب"
                                                                              },
                                                                              on: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.setStatus(
                                                                                    index,
                                                                                    x,
                                                                                    4
                                                                                  )
                                                                                }
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "fa fa-pause"
                                                                                }
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      x.status_id ==
                                                                      1
                                                                        ? _c(
                                                                            "button",
                                                                            {
                                                                              directives: [
                                                                                {
                                                                                  name:
                                                                                    "b-tooltip",
                                                                                  rawName:
                                                                                    "v-b-tooltip.hover",
                                                                                  modifiers: {
                                                                                    hover: true
                                                                                  }
                                                                                }
                                                                              ],
                                                                              staticClass:
                                                                                "btn btn-sm btn-danger",
                                                                              attrs: {
                                                                                type:
                                                                                  "button",
                                                                                title:
                                                                                  "رفض الطلب"
                                                                              },
                                                                              on: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.setStatus(
                                                                                    index,
                                                                                    x,
                                                                                    2
                                                                                  )
                                                                                }
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "fas fa-times"
                                                                                }
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e()
                                                                    ]
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ])
                                ])
                              ])
                            ]
                          )
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-modal",
                      {
                        ref: "form-modal",
                        staticStyle: { padding: "0px" },
                        attrs: {
                          size: "lg",
                          "hide-footer": "",
                          "hide-header": "",
                          "no-close-on-esc": "",
                          "no-close-on-backdrop": ""
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "card-header text-center mt-6 pb-3 border-bottom"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "card-title text-success text-center"
                              },
                              [
                                _c("h3", { staticClass: "text-success" }, [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(_vm.ModalTitle) +
                                      "\n                "
                                  )
                                ])
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "card-body pt-4 text-right" },
                          [
                            _c(
                              "form",
                              { attrs: { id: "form", name: "form" } },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "row text-right px-1 px-lg-1 py-3 py-lg-3"
                                  },
                                  [
                                    _c("div", { staticClass: "row" }, [
                                      _c("div", { staticClass: "col-lg-12" }, [
                                        _c("div", { staticClass: "row" }, [
                                          _c(
                                            "div",
                                            { staticClass: "col-lg-3 fv-row" },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "col-form-label fw-bolder "
                                                },
                                                [_vm._v(" رقم الطلب")]
                                              ),
                                              _c(
                                                "p",
                                                { staticClass: "form-control" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(_vm.req.request_no)
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "col-lg-3 fv-row" },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "col-form-label fw-bolder "
                                                },
                                                [_vm._v(" حالة الطلب")]
                                              ),
                                              _c(
                                                "p",
                                                { staticClass: "form-control" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.req.status.name
                                                      )
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "col-lg-3 fv-row" },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "col-form-label fw-bolder "
                                                },
                                                [_vm._v(" تصنيف الخدمة ")]
                                              ),
                                              _c(
                                                "p",
                                                { staticClass: "form-control" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.req.service_category
                                                          .name
                                                      )
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "col-lg-3 fv-row" },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "col-form-label fw-bolder "
                                                },
                                                [_vm._v("الخدمة ")]
                                              ),
                                              _c(
                                                "p",
                                                { staticClass: "form-control" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.req.service.name
                                                      )
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "col-lg-3 fv-row" },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "col-form-label fw-bolder "
                                                },
                                                [_vm._v("مقدم الطلب ")]
                                              ),
                                              _c(
                                                "p",
                                                { staticClass: "form-control" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.req.applicant_name
                                                      )
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "col-lg-3 fv-row" },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "col-form-label fw-bolder "
                                                },
                                                [_vm._v(" هوية مقدم الطلب ")]
                                              ),
                                              _c(
                                                "p",
                                                { staticClass: "form-control" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.req.applicant_id_no
                                                      )
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "col-lg-3 fv-row" },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "col-form-label fw-bolder "
                                                },
                                                [_vm._v("رقم المبني ")]
                                              ),
                                              _c(
                                                "p",
                                                { staticClass: "form-control" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.req.building_no
                                                      )
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "col-lg-3 fv-row" },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "col-form-label fw-bolder "
                                                },
                                                [_vm._v("رقم الشارع ")]
                                              ),
                                              _c(
                                                "p",
                                                { staticClass: "form-control" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(_vm.req.street_no)
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "col-lg-3 fv-row" },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "col-form-label fw-bolder "
                                                },
                                                [_vm._v("رقم حساب البلدية")]
                                              ),
                                              _c(
                                                "p",
                                                { staticClass: "form-control" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.req
                                                          .municipality_account_no
                                                      )
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "col-lg-3 fv-row" },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "col-form-label fw-bolder "
                                                },
                                                [_vm._v("اسم المنتفع")]
                                              ),
                                              _c(
                                                "p",
                                                { staticClass: "form-control" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.req.beneficiary_name
                                                      )
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "col-lg-3 fv-row" },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "col-form-label fw-bolder "
                                                },
                                                [_vm._v("رقم هوية المنتفع ")]
                                              ),
                                              _c(
                                                "p",
                                                { staticClass: "form-control" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.req
                                                          .beneficiary_id_no
                                                      )
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "col-lg-3 fv-row" },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "col-form-label fw-bolder "
                                                },
                                                [_vm._v("اسم المالك")]
                                              ),
                                              _c(
                                                "p",
                                                { staticClass: "form-control" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(_vm.req.owner_name)
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "col-lg-3 fv-row" },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "col-form-label fw-bolder "
                                                },
                                                [_vm._v("رقم هوية المالك ")]
                                              ),
                                              _c(
                                                "p",
                                                { staticClass: "form-control" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.req.owner_id_no
                                                      )
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "col-lg-12 fv-row" },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "col-form-label fw-bolder "
                                                },
                                                [_vm._v("تفاصيل الطلب")]
                                              ),
                                              _c(
                                                "p",
                                                { staticClass: "form-control" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(_vm.req.details)
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ])
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "col-lg-12 fv-row pt-3"
                                          },
                                          [
                                            _c(
                                              "h4",
                                              {
                                                staticClass:
                                                  "text-right text-blue"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                          ملاك الخدمة\n                          "
                                                ),
                                                _c(
                                                  "span",
                                                  { staticClass: "text-dark" },
                                                  [
                                                    _vm._v(
                                                      "( عدد الملاك:  " +
                                                        _vm._s(
                                                          _vm.req.service_owners
                                                            .length
                                                        ) +
                                                        "  )"
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _vm.req.service_owners.length > 0
                                        ? _c(
                                            "div",
                                            { staticClass: "row mb-6" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "col-lg-12 fv-row pt-3"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "table-responsive"
                                                    },
                                                    [
                                                      _c(
                                                        "table",
                                                        {
                                                          staticClass:
                                                            "table table-bordered align-middle fs-6 gy-5 table-row-dashed  table-hover table-checkable dataTable dtr-inline mt-5"
                                                        },
                                                        [
                                                          _c("thead", [
                                                            _c(
                                                              "tr",
                                                              {
                                                                staticClass:
                                                                  "text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0"
                                                              },
                                                              [
                                                                _c(
                                                                  "th",
                                                                  {
                                                                    staticClass:
                                                                      "text-center"
                                                                  },
                                                                  [_vm._v("#")]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "th",
                                                                  {
                                                                    staticClass:
                                                                      "text-center"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "الاسم"
                                                                    )
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "th",
                                                                  {
                                                                    staticClass:
                                                                      "text-center"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "رقم الهوية"
                                                                    )
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "th",
                                                                  {
                                                                    staticClass:
                                                                      "text-center"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "رقم الجوال"
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            )
                                                          ]),
                                                          _vm._v(" "),
                                                          _c(
                                                            "tbody",
                                                            {
                                                              staticClass:
                                                                "fw-bold "
                                                            },
                                                            _vm._l(
                                                              _vm.req
                                                                .service_owners,
                                                              function(
                                                                x,
                                                                index
                                                              ) {
                                                                return _c(
                                                                  "tr",
                                                                  {
                                                                    key: index
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "td",
                                                                      {
                                                                        staticClass:
                                                                          "text-center "
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              index +
                                                                                1
                                                                            )
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "td",
                                                                      {
                                                                        staticClass:
                                                                          "text-center "
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              x.name
                                                                            )
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "td",
                                                                      {
                                                                        staticClass:
                                                                          "text-center "
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              x.id_no
                                                                            )
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "td",
                                                                      {
                                                                        staticClass:
                                                                          "text-center "
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              x.mobile
                                                                            )
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                            0
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("div", {
                                        staticClass: "separator mx-1 my-3"
                                      }),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "col-lg-12 fv-row pt-3"
                                          },
                                          [
                                            _c(
                                              "h4",
                                              {
                                                staticClass:
                                                  "text-right text-blue"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                          المرفقات\n                          "
                                                ),
                                                _c(
                                                  "span",
                                                  { staticClass: "text-dark" },
                                                  [
                                                    _vm._v(
                                                      "( عدد المرفقات :  " +
                                                        _vm._s(
                                                          _vm.req.attachments
                                                            .length
                                                        ) +
                                                        "  )"
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _vm.req.attachments.length > 0
                                        ? _c(
                                            "div",
                                            { staticClass: "row mb-6" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "col-lg-12 fv-row pt-3"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "table-responsive"
                                                    },
                                                    [
                                                      _c(
                                                        "table",
                                                        {
                                                          staticClass:
                                                            "table table-bordered align-middle fs-6 gy-5 table-row-dashed  table-hover table-checkable dataTable dtr-inline mt-5"
                                                        },
                                                        [
                                                          _c("thead", [
                                                            _c(
                                                              "tr",
                                                              {
                                                                staticClass:
                                                                  "text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0"
                                                              },
                                                              [
                                                                _c(
                                                                  "th",
                                                                  {
                                                                    staticClass:
                                                                      "text-center"
                                                                  },
                                                                  [_vm._v("#")]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "th",
                                                                  {
                                                                    staticClass:
                                                                      "text-center"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "نوع المرفق"
                                                                    )
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "th",
                                                                  {
                                                                    staticClass:
                                                                      "text-center"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "ملاحظات"
                                                                    )
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "th",
                                                                  {
                                                                    staticClass:
                                                                      "text-center"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "تاريخ الارفاق"
                                                                    )
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "th",
                                                                  {
                                                                    staticClass:
                                                                      "text-center"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "العمليات"
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            )
                                                          ]),
                                                          _vm._v(" "),
                                                          _c(
                                                            "tbody",
                                                            {
                                                              staticClass:
                                                                "fw-bold "
                                                            },
                                                            _vm._l(
                                                              _vm.req
                                                                .attachments,
                                                              function(
                                                                x,
                                                                index
                                                              ) {
                                                                return _c(
                                                                  "tr",
                                                                  {
                                                                    key: index
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "td",
                                                                      {
                                                                        staticClass:
                                                                          "text-center "
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              index +
                                                                                1
                                                                            )
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "td",
                                                                      {
                                                                        staticClass:
                                                                          "text-center "
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              x
                                                                                .attachment
                                                                                .name
                                                                            )
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "td",
                                                                      {
                                                                        staticClass:
                                                                          "text-center "
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              x.notes
                                                                            )
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "td",
                                                                      {
                                                                        staticClass:
                                                                          "text-center "
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "  " +
                                                                            _vm._s(
                                                                              _vm.onlyDate(
                                                                                x.created_at
                                                                              )
                                                                            )
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "td",
                                                                      {
                                                                        staticClass:
                                                                          "text-center  text-end"
                                                                      },
                                                                      [
                                                                        x.path !=
                                                                        null
                                                                          ? _c(
                                                                              "button",
                                                                              {
                                                                                directives: [
                                                                                  {
                                                                                    name:
                                                                                      "b-tooltip",
                                                                                    rawName:
                                                                                      "v-b-tooltip.hover",
                                                                                    modifiers: {
                                                                                      hover: true
                                                                                    }
                                                                                  }
                                                                                ],
                                                                                staticClass:
                                                                                  "btn btn-sm btn-success ",
                                                                                attrs: {
                                                                                  type:
                                                                                    "button",
                                                                                  title:
                                                                                    "تنزيل المرفق"
                                                                                },
                                                                                on: {
                                                                                  click: function(
                                                                                    $event
                                                                                  ) {
                                                                                    $event.preventDefault()
                                                                                    return _vm.download(
                                                                                      x.id
                                                                                    )
                                                                                  }
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "span",
                                                                                  {
                                                                                    staticClass:
                                                                                      "fa fa-download"
                                                                                  }
                                                                                )
                                                                              ]
                                                                            )
                                                                          : _vm._e()
                                                                      ]
                                                                    )
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                            0
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          " col-lg-12 col-md-12 col-sm-12   pl-5 mt-6 action  border-top pt-4 ",
                                        staticStyle: { float: "right" }
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "btn btn-danger",
                                            attrs: { type: "button" },
                                            on: { click: _vm.closeShowAction }
                                          },
                                          [_vm._v("اغلاق")]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  ],
                  1
                )
              ])
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header border-0" }, [
      _c("div", { staticClass: "card-toolbar" }, [
        _c(
          "div",
          {
            staticClass:
              "\n                  menu\n                  menu-sub\n                  menu-sub-dropdown\n                  menu-column\n                  menu-rounded\n                  menu-gray-800\n                  menu-state-bg-light-success\n                  fw-bold\n                  w-200px\n                  py-3\n                ",
            attrs: { "data-kt-menu": "true" }
          },
          [
            _c("div", { staticClass: "menu-item px-3" }, [
              _c(
                "div",
                {
                  staticClass:
                    "\n                      menu-content\n                      text-muted\n                      pb-2\n                      px-3\n                      fs-7\n                      text-uppercase\n                    "
                },
                [_vm._v("\n                    Payments\n                  ")]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "menu-item px-3" }, [
              _c("a", { staticClass: "menu-link px-3", attrs: { href: "#" } }, [
                _vm._v("Create Invoice")
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "menu-item px-3" }, [
              _c(
                "a",
                {
                  staticClass: "menu-link flex-stack px-3",
                  attrs: { href: "#" }
                },
                [
                  _vm._v("Create Payment\n                    "),
                  _c("i", {
                    staticClass: "fas fa-exclamation-circle ms-2 fs-7",
                    attrs: {
                      "data-bs-toggle": "tooltip",
                      title:
                        "Specify a target name for future usage and reference"
                    }
                  })
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "menu-item px-3" }, [
              _c("a", { staticClass: "menu-link px-3", attrs: { href: "#" } }, [
                _vm._v("Generate Bill")
              ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "menu-item px-3",
                attrs: {
                  "data-kt-menu-trigger": "hover",
                  "data-kt-menu-placement": "left-start",
                  "data-kt-menu-flip": "center, top"
                }
              },
              [
                _c(
                  "a",
                  { staticClass: "menu-link px-3", attrs: { href: "#" } },
                  [
                    _c("span", { staticClass: "menu-title" }, [
                      _vm._v("Subscription")
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "menu-arrow" })
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "menu-sub menu-sub-dropdown w-175px py-4" },
                  [
                    _c("div", { staticClass: "menu-item px-3" }, [
                      _c(
                        "a",
                        { staticClass: "menu-link px-3", attrs: { href: "#" } },
                        [_vm._v("Plans")]
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "menu-item px-3" }, [
                      _c(
                        "a",
                        { staticClass: "menu-link px-3", attrs: { href: "#" } },
                        [_vm._v("Billing")]
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "menu-item px-3" }, [
                      _c(
                        "a",
                        { staticClass: "menu-link px-3", attrs: { href: "#" } },
                        [_vm._v("Statements")]
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "separator my-2" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "menu-item px-3" }, [
                      _c("div", { staticClass: "menu-content px-3" }, [
                        _c(
                          "label",
                          {
                            staticClass:
                              "\n                            form-check\n                            form-switch\n                            form-check-custom\n                            form-check-solid\n                          "
                          },
                          [
                            _c("input", {
                              staticClass: "form-check-input w-30px h-20px",
                              attrs: {
                                type: "checkbox",
                                value: "1",
                                checked: "checked",
                                name: "notifications"
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass: "form-check-label text-muted fs-6"
                              },
                              [_vm._v("Recuring")]
                            )
                          ]
                        )
                      ])
                    ])
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "menu-item px-3 my-1" }, [
              _c("a", { staticClass: "menu-link px-3", attrs: { href: "#" } }, [
                _vm._v("Settings")
              ])
            ])
          ]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "text-center d-block my-2 image-center text-warning",
        staticStyle: { "font-size": "35px !important" }
      },
      [_c("span", { staticClass: "fas fa-wallet" })]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "h3",
      {
        staticClass: "txt-color-gray fw-bold fs-6 mt-2 text-center text-warning"
      },
      [_c("b", [_vm._v("طلبات المعاملات")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "text-center d-block my-2 image-center text-success",
        staticStyle: { "font-size": "35px !important" }
      },
      [_c("span", { staticClass: "fas fa-exchange-alt " })]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "h3",
      {
        staticClass: "txt-color-gray fw-bold fs-6 mt-2 text-success text-center"
      },
      [_c("b", [_vm._v("عدد المعاملات")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "text-center d-block my-2 image-center text-danger",
        staticStyle: { "font-size": "35px !important" }
      },
      [_c("span", { staticClass: "fas fa-tags" })]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "h3",
      {
        staticClass: "txt-color-gray fw-bold fs-6 mt-2 text-center text-danger"
      },
      [_c("b", [_vm._v("عدد المقترحات")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      {
        staticClass: "text-center d-block my-2 image-center text-success",
        staticStyle: { "font-size": "35px !important" }
      },
      [_c("span", { staticClass: "bi bi-briefcase" })]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "h3",
      {
        staticClass: "txt-color-gray fw-bold fs-6 mt-2 text-center text-success"
      },
      [_c("b", [_vm._v("عدد الشكاوى")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "no-record-block bg-light-warning text-warning" },
      [
        _c("div", { staticClass: "row text-center" }, [
          _c("div", { staticClass: "col-lg-12 text-center" }, [
            _c("label", { staticClass: "fw-bolder" }, [
              _vm._v("لايوجد سجلات للعرض ")
            ])
          ])
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c(
        "tr",
        {
          staticClass:
            " text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0"
        },
        [
          _c("th", { staticClass: "text-center" }, [_vm._v("#")]),
          _vm._v(" "),
          _c("th", { staticClass: "text-center" }, [_vm._v("رقم الطلب")]),
          _vm._v(" "),
          _c("th", { staticClass: "text-center" }, [_vm._v("مقدم الطلب")]),
          _vm._v(" "),
          _c("th", { staticClass: "text-center" }, [
            _vm._v("رقم هوية "),
            _c("br"),
            _vm._v("مقدم الطلب")
          ]),
          _vm._v(" "),
          _c("th", { staticClass: "text-center" }, [_vm._v("تصنيف الخدمة")]),
          _vm._v(" "),
          _c("th", { staticClass: "text-center" }, [_vm._v("الخدمة")]),
          _vm._v(" "),
          _c("th", { staticClass: "text-center" }, [_vm._v("تاريخ الطلب")]),
          _vm._v(" "),
          _c("th", { staticClass: "text-center text-end" }, [
            _vm._v("العمليات")
          ])
        ]
      )
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-e3ad3120", { render: render, staticRenderFns: staticRenderFns })
  }
}
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "Footer footer py-4 d-flex flex-lg-column",
        attrs: { id: "kt_footer" }
      },
      [
        _c(
          "div",
          {
            staticClass:
              "container-fluid d-flex flex-column flex-md-row align-items-center justify-content-between"
          },
          [
            _c("div", { staticClass: "text-dark order-2 order-md-1" }, [
              _c(
                "a",
                {
                  staticClass: "text-gray-800 text-hover-success",
                  attrs: {
                    href: "https://nepras.ps/index.html#/",
                    target: "_blank"
                  }
                },
                [_vm._v("شركة نبراس للاعلام وتكنولوجيا المعلومات")]
              ),
              _vm._v(" "),
              _c("span", { staticClass: "text-muted fw-bold me-1" }, [
                _vm._v("©")
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "text-muted fw-bold me-1" }, [
                _vm._v("2021")
              ])
            ])
          ]
        )
      ]
    )
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-8e5606d2", { render: render, staticRenderFns: staticRenderFns })
  }
}
import Vue from "vue";
import App from "./App";
import { store } from "./core/store";
import { router } from './router';

import "bootstrap";
window.bootstrap = require("bootstrap/dist/js/bootstrap.bundle.js");

import "popper.js";
import "./core/plugins/vuex";
import "./core/plugins/bootstrap-vue";
import "./core/plugins/inline-svg";
import "./core/plugins/vee-validate";
import "./core/plugins/datepicker";
import "./core/plugins/multiselect";
import "./core/plugins/fontawesome";
import "./core/plugins/toaster";
import "./core/plugins/vue-simple-alert";
import "./core/plugins/jquery";

import "./assets/js/scripts.bundle";
import "./assets/js/plugins.bundle";


Vue.mixin({
  methods: {
      isNumber: function(evt) {
          evt = (evt) ? evt : window.event;
          var charCode = (evt.which) ? evt.which : evt.keyCode;
          if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
              evt.preventDefault();;
          } else {
              return true;
          }
      },
      isFloat: function(evt) {
          evt = (evt) ? evt : window.event;
          var charCode = (evt.which) ? evt.which : evt.keyCode;
          if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
              evt.preventDefault();;
          } else {
              return true;
          }
      },
    onlyDate (dateString) {

      var dt = new Date(dateString);
      var date = dt.getDate();
      var month = dt.getMonth();
      var year = dt.getFullYear();

      return year + "-" +(month + 1) + "-" + date;
    },
  }
});


new Vue({
  el: "#app",
  router,
  store,
  render: (h) => h(App),
});

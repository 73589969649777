<template>
  <!--begin::Main-->
  <!--begin::Root-->
  <div class="d-flex flex-column flex-root">
    <!--begin::Page-->
    <div class="page d-flex flex-row flex-column-fluid">
      <!--begin::Aside-->
      <Aside></Aside>
      <!--end::Aside-->
      <!--begin::Wrapper-->
      <div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">

        <!--begin::Header-->
        <Header></Header>
        <!--end::Header-->
        <!--begin::Content-->
        <router-view />
        <!--end::Content-->
        <!--begin::Footer-->
        <Footer></Footer>
        <!--end::Footer-->
      </div>

      <!--end::Wrapper-->
    </div>
    <!--end::Page-->
  </div>
  <!--end::Root-->
  <!--begin::Modals-->

  <!--end::Modals-->
  <!--begin::Scrolltop-->

  <!--end::Scrolltop-->
  <!--end::Main-->
</template>

<script>
import Header from "../layout/Header";
import Footer from "../layout/Footer";
import Aside from "../layout/Aside";
// import Header from "@/layout/Header";
// import Footer from "@/layout/Footer";
// import Aside from "@/layout/Aside";
export default {
  name: "Home",
  components: { Header, Footer, Aside },
  data() {
    return {};
  },
  computed: {
    loggedIn() {
      return this.$store.state.authentication.status.loggedIn;
    },
    user() {
      return this.$store.state.authentication.user;
    },
  },
  created() {
    if (!this.loggedIn) {
      // this.$router.push('/login');
    }
  },
  methods: {},
  mounted() {
    if (!this.currentUser) {
      // this.$router.push('/login');
    }
  },
};
</script>

<style scoped >
</style>
const mainUrl  = 'https://admin.nuseirat.ps/';
const apiUrl  = 'https://nuseirat.ps/portal-api/public/api/v1.0/Admin/';
const storageUrl='https://nuseirat.ps/portal-api/public/documents/';
const imgUrl='https://nuseirat.ps/portal-api/public/api/v1.0/Admin/images/';
const logout='https://nuseirat.ps/portal-api/public/api/v1.0/logout';
const login='https://nuseirat.ps/portal-api/public/api/v1.0/login';


export default {mainUrl,apiUrl,storageUrl,imgUrl,logout,login};




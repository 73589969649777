import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import config from './config';
import { userService } from "./user.service";

/**
 * Service to call HTTP request via Axios
 */
const ApiServices = {
  init() {
    Vue.use(VueAxios, axios);
    axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
    // axios.defaults.headers.common['Access-Control-Allow-Methods'] = 'POST, GET, OPTIONS, PUT, DELETE';
    // axios.defaults.headers.common['Access-Control-Allow-Headers'] = 'Content-Type, X-Auth-Token, Origin, Authorization';


    // axios.defaults.baseURL = "http://localhost/";
  },

  getLoginURLPath(){
    return config.login;
  },

    getMainCpPath (){
    return config.mainUrl;
  },
  getStoragePath(){
    return config.storageUrl;
  },
  getImgPath(){
    return config.imgUrl;
  },
  getapiUrl(){
    return config.apiUrl;
  },
    getHeader() {
        let user = JSON.parse(localStorage.getItem('user'));
        let token = JSON.parse(localStorage.getItem('token'));

        if (token) {
            return { 'Authorization': 'Bearer ' + token };
        } else {
            return {};
        }
    },

  query(resource, params) {
    return axios.get(`${config.apiUrl}${resource}`, { headers: this.getHeader(), params : {...params} }).catch(error => {
      userService.handleResponse(error.response);
      throw new Error(`[KT] ApiService ${error}`);
      
    });
  },
  
  filter(resource, params) {
    return axios.post(`${config.apiUrl}${resource}/filter`, params, { headers: this.getHeader() }).catch(error => {
      userService.handleResponse(error.response);
      throw new Error(`[KT] ApiService ${error}`);
    });
  },
    downloadFilter(resource,params) {
        return axios.post(`${config.apiUrl}${resource}`, params,{ headers: this.getHeader() , responseType: 'arraybuffer'}).catch(error => {
          userService.handleResponse(error.response);
          throw new Error(`[KT] ApiService ${error}`);
    });
    },

  get(resource) {
    return axios.get(`${config.apiUrl}${resource}`, { headers: this.getHeader() }).catch(error => {
      userService.handleResponse(error.response);
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

    download(resource) {
    return axios.get(`${config.apiUrl}${resource}`, { headers: this.getHeader() , responseType: 'arraybuffer'}).catch(error => {
      userService.handleResponse(error.response);
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  getOptions(resource) {
    return axios.get(`${config.apiUrl}${resource}/getOptions`, { headers: this.getHeader() }).catch(error => {
      userService.handleResponse(error.response);
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  post(resource, params) {
    return axios.post(`${config.apiUrl}${resource}`, params, { headers: this.getHeader() });
  },

  update(resource, slug, params) {
    return axios.put(`${config.apiUrl}${resource}/${slug}`, params, { headers: this.getHeader() });
  },

  put(resource, params) {
    return axios.put(`${config.apiUrl}${resource}`, params, { headers: this.getHeader() });
  },

  login(resource, params) {
    return axios.post(`${config.login}${resource}`, params, { headers: this.getHeader() });
  },

  delete(resource) {
    // return axios.delete('http://localhost/_mobadarat-backend/public/api/v1.0/Admin/'+`${resource}`, { headers: this.getHeader() }).catch(error => {
    //   throw new Error(`[RWV] ApiService ${error}`);
    // });

      let url =  config.apiUrl + resource ;

      return axios.delete(url, { headers: this.getHeader() }).catch(error => {
          throw new Error(`[RWV] ApiService ${error}`);
  });
  },

  logout(resource, params) {
    return axios.post(`${config.logout}${resource}`, params, { headers: this.getHeader() });
},

};
export default ApiServices;

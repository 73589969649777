<template>
  <!--begin::Main-->
  <div class="d-flex flex-column flex-root">
    <!--begin::Authentication - Sign-in -->
    <div class="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
         v-bind:style="{ 'background-image': 'url(' + bgSrc + ')' }">
      <!--begin::Content-->
      <div class="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
        <!--begin::Logo-->
        <!--end::Logo-->
        <!--begin::Wrapper-->
        <div class="w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto mt-12">
          <!--begin::Form-->
          <ValidationObserver v-slot="{ invalid }">
            <form name="form" class="form w-100" @submit.prevent="handleSubmit">
              <!--begin::Heading-->
              <div class="text-center mb-7 mt-5">
                <!--begin::Title-->
                <a class="mb-12">
                  <img alt="Logo" :src="bgLogo" class="h-150px" />
                </a>
                <br>
                <h1 class="text-success mb-3 mt-5">تسجيل الدخول
                  <br>
                  نظام خدمات البلدية</h1>
                <!--end::Title-->
              </div>
              <!--begin::Heading-->
              <!--<div class="fv-row text-right">-->
                <!--<div class="form-group">-->
                  <!--<ValidationProvider name="رقم الهوية" :rules="{ required: true }" v-slot="{ errors }">-->
                    <!--<div class="input-group mb-3">-->
                      <!--<div class="input-group-prepend">-->
                        <!--<span class="input-group-text text-primary fw-bolder fa fa-envelope" id="basic-addon1"></span>-->
                      <!--</div>-->
                      <!--<input-->
                              <!--id="id_number"-->
                              <!--v-model="id_number"-->
                              <!--type="text"-->
                              <!--class="form-control form-control-lg form-control-solid"-->
                              <!--name="id_number"-->
                      <!--/>-->
                    <!--</div>-->
                    <!--<p v-if="errors.length > 0" class="text-danger fs-8 fw-bolder" role="alert">{{ errors[0] }}</p>-->
                  <!--</ValidationProvider>-->
                <!--</div>-->
              <!--</div>-->
              <div class="fv-row">
                <div class="form-group">
                  <!--<button class="btn btn-lg btn-primary   w-100 mb-5 btn-block" :disabled="invalid || loading">-->
                    <!--<span v-show="loading" class="spinner-border spinner-border-sm align-middle ms-2 ml-2 mr-2"></span>-->
                    <!--<span class="indicator-label">-->
                    <!--<span v-show="!loading">تسجيل الدخول </span>-->
                    <!--<span v-show="loading"> الرجاء الانتظار ...</span>-->
                  <!--</span>-->
                  <!--</button>-->
                  <a  class="btn btn-lg btn-success  w-100 mb-5 btn-block"
                      type="button" :href="url">
                    تسجيل الدخول
                  </a>
                </div>
              </div>
              <div class="fv-row mb-10">

              </div>

            </form>
          </ValidationObserver>
        </div>
        <!--end::Wrapper-->
      </div>
      <!--end::Content-->
    </div>
    <!--end::Authentication - Sign-in-->
  </div>
  <!--end::Main-->
</template>

<script>
    import bgImg from  '../../assets/media/illustrations/sketchy-1/14.png'
    import bgLogo from  '../../assets/media/logos/logo-png.png'
    import ApiServices from "../../core/services/api.services";

    export default {
        data () {
            return {
                bgSrc : bgImg ,
                bgLogo : bgLogo ,
                id_number: '802169722',
                url: '',
                submitted: false ,
                loading: false,
            }
        },
        computed: {
            loggingIn () {
                return this.$store.state.authentication.status.loggingIn;
            }
        },
        created () {
            this.url = ApiServices.getMainCpPath()+'sso.php';
            // reset login status
            // this.$store.dispatch('authentication/logout');
        },
        methods: {
            handleSubmit (e) {
                // if ( id_number ) {
                    this.submitted = true;
                    const { id_number } = this;
                    const { dispatch } = this.$store;
                    if (id_number) {
                        dispatch('authentication/login', { id_number });
                    }
                // }
            }
        },
        watch:{
            $route (to, from){

            }
        }
    };
</script>

<template>
  <div
      class="Dashboard content d-flex flex-column flex-column-fluid"
      id="kt_content"
  >
    <!--begin::Toolbar-->
    <div class="toolbar" id="kt_toolbar">
      <!--begin::Container-->
      <div id="kt_toolbar_container" class="container-fluid d-flex flex-stack">
        <!--begin::Page title-->
        <div
            data-kt-place="true"
            data-kt-place-mode="prepend"
            data-kt-place-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
            class="
            page-title
            d-flex
            align-items-center
            me-3
            flex-wrap
            mb-5 mb-lg-0
            lh-1
          "
        >
          <!--&lt;!&ndash;begin::Title&ndash;&gt;-->
          <!--<h1 class="d-flex align-items-center text-dark fw-bolder my-1 fs-3">-->
          <!--<span class="fa fa-cogs"></span>-->
          <!--Albums-->
          <!--</h1>-->
          <!--&lt;!&ndash;end::Title&ndash;&gt;-->
          <!--&lt;!&ndash;begin::Separator&ndash;&gt;-->
          <!--<span class="h-20px border-gray-200 border-start mx-4"></span>-->
          <!--&lt;!&ndash;end::Separator&ndash;&gt;-->
          <!--begin::Breadcrumb-->
          <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
            <!--begin::Item-->
            <li class="breadcrumb-item text-muted">
              <router-link to="/" class="text-success fw-bolder">
                <span class="fa fa-home"></span>
                الرئيسية
              </router-link>
            </li>
            <!--end::Item-->
          </ul>
          <!--end::Breadcrumb-->
        </div>
        <!--end::Page title-->
        <!--begin::Actions-->
        <div class="d-flex align-items-center py-1">
          <!--begin::Button-->

          <!--end::Button-->
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Container-->
    </div>
    <!--end::Toolbar-->
    <!--begin::Post-->
    <div class="post d-flex flex-column-fluid" id="kt_post">
      <!--begin::Container-->
      <div id="kt_content_container" class="container">
        <!--begin::Card-->
        <div class="card card-xxl-stretch">
          <!--begin::Header-->
          <div class="card-header border-0">
            <div class="card-toolbar">
              <!--begin::Menu 3-->
              <div
                  class="
                  menu
                  menu-sub
                  menu-sub-dropdown
                  menu-column
                  menu-rounded
                  menu-gray-800
                  menu-state-bg-light-success
                  fw-bold
                  w-200px
                  py-3
                "
                  data-kt-menu="true"
              >
                <!--begin::Heading-->
                <div class="menu-item px-3">
                  <div
                      class="
                      menu-content
                      text-muted
                      pb-2
                      px-3
                      fs-7
                      text-uppercase
                    "
                  >
                    Payments
                  </div>
                </div>
                <!--end::Heading-->
                <!--begin::Menu item-->
                <div class="menu-item px-3">
                  <a href="#" class="menu-link px-3">Create Invoice</a>
                </div>
                <!--end::Menu item-->
                <!--begin::Menu item-->
                <div class="menu-item px-3">
                  <a href="#" class="menu-link flex-stack px-3"
                  >Create Payment
                    <i
                        class="fas fa-exclamation-circle ms-2 fs-7"
                        data-bs-toggle="tooltip"
                        title="Specify a target name for future usage and reference"
                    ></i
                    ></a>
                </div>
                <!--end::Menu item-->
                <!--begin::Menu item-->
                <div class="menu-item px-3">
                  <a href="#" class="menu-link px-3">Generate Bill</a>
                </div>
                <!--end::Menu item-->
                <!--begin::Menu item-->
                <div
                    class="menu-item px-3"
                    data-kt-menu-trigger="hover"
                    data-kt-menu-placement="left-start"
                    data-kt-menu-flip="center, top"
                >
                  <a href="#" class="menu-link px-3">
                    <span class="menu-title">Subscription</span>
                    <span class="menu-arrow"></span>
                  </a>
                  <!--begin::Menu sub-->
                  <div class="menu-sub menu-sub-dropdown w-175px py-4">
                    <!--begin::Menu item-->
                    <div class="menu-item px-3">
                      <a href="#" class="menu-link px-3">Plans</a>
                    </div>
                    <!--end::Menu item-->
                    <!--begin::Menu item-->
                    <div class="menu-item px-3">
                      <a href="#" class="menu-link px-3">Billing</a>
                    </div>
                    <!--end::Menu item-->
                    <!--begin::Menu item-->
                    <div class="menu-item px-3">
                      <a href="#" class="menu-link px-3">Statements</a>
                    </div>
                    <!--end::Menu item-->
                    <!--begin::Menu separator-->
                    <div class="separator my-2"></div>
                    <!--end::Menu separator-->
                    <!--begin::Menu item-->
                    <div class="menu-item px-3">
                      <div class="menu-content px-3">
                        <!--begin::Switch-->
                        <label
                            class="
                            form-check
                            form-switch
                            form-check-custom
                            form-check-solid
                          "
                        >
                          <!--begin::Input-->
                          <input
                              class="form-check-input w-30px h-20px"
                              type="checkbox"
                              value="1"
                              checked="checked"
                              name="notifications"
                          />
                          <!--end::Input-->
                          <!--end::Label-->
                          <span class="form-check-label text-muted fs-6"
                          >Recuring</span
                          >
                          <!--end::Label-->
                        </label>
                        <!--end::Switch-->
                      </div>
                    </div>
                    <!--end::Menu item-->
                  </div>
                  <!--end::Menu sub-->
                </div>
                <!--end::Menu item-->
                <!--begin::Menu item-->
                <div class="menu-item px-3 my-1">
                  <a href="#" class="menu-link px-3">Settings</a>
                </div>
                <!--end::Menu item-->
              </div>
              <!--end::Menu 3-->
              <!--end::Menu-->
            </div>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body p-0">
            <!--begin::Stats-->
            <div class="card-p mt-n20 position-relative">
              <!--begin::Row-->
              <div class="row g-0">
                <!--begin::Col-->
                <div
                    class="
                    col-sm
                    bg-light-warning
                    px-6
                    py-8
                    rounded-4
                    me-7
                    mb-7
                    d-flex
                    flex-column
                    corner
                  "
                >

                   <span class="text-center d-block my-2 image-center text-warning" style="font-size: 35px !important;">
                    <span class="fas fa-wallet"></span>
                  </span>

                  <h3 class="txt-color-gray fw-bold fs-6 mt-2 text-center text-warning">
                    <b>طلبات المعاملات</b>
                  </h3>
                  <!--end::Svg Icon-->
                  <h3 class="txt-color-gray fw-bold fs-6 mt-2 text-center text-warning">
                    <b>{{row.requests}}</b>
                  </h3>
                </div>
                <!--end::Col-->
                <!--begin::Col-->
                <div
                    class="
                    col-sm
                    bg-light-success
                    px-6
                    py-8
                    rounded-4
                    me-7
                    mb-7
                    d-flex
                    flex-column
                    corner
                  "
                >

                  <span class="text-center d-block my-2 image-center text-success" style="font-size: 35px !important;">
                    <span class="fas fa-exchange-alt "></span>
                  </span>
                  <h3 class="txt-color-gray fw-bold fs-6 mt-2 text-success text-center">
                    <b>عدد المعاملات</b>
                  </h3>
                  <h3 class="txt-color-gray fw-bold fs-6 mt-2 text-success text-center">
                    <b>{{row.transactions}}</b>
                  </h3>
                </div>
                <!--end::Col-->
                <!--begin::Col-->
                <div
                    class="
                    col-sm
                    bg-light-danger
                     me-4 px-6
                    py-8
                    rounded-4
                    me-7
                    mb-7
                    d-flex
                    flex-column
                    corner
                  "
                >

                   <span class="text-center d-block my-2 image-center text-danger" style="font-size: 35px !important;">
                    <span class="fas fa-tags"></span>
                  </span>

                  <h3 class="txt-color-gray fw-bold fs-6 mt-2 text-center text-danger">
                    <b>عدد المقترحات</b>
                  </h3>

                  <h3 class="txt-color-gray fw-bold fs-6 mt-2 text-center text-danger">
                    <b>{{row.suggestions}}</b>
                  </h3>
                </div>
                <!--end::Col-->
                <!--begin::Col-->
                <div
                    class="
                    col-sm
                     bg-light-success
                    px-6
                    py-8
                    rounded-4
                    me-7
                    mb-7
                    d-flex
                    flex-column
                    corner
                  "
                >
                  <!--begin::Svg Icon | path: icons/duotone/Communication/Add-user.svg-->
                  <span class="text-center d-block my-2 image-center text-success" style="font-size: 35px !important;">
                    <span class="bi bi-briefcase"></span>
                  </span>
                  <h3 class="txt-color-gray fw-bold fs-6 mt-2 text-center text-success">
                    <b>عدد الشكاوى</b>
                  </h3>
                  <!--end::Svg Icon-->
                  <h3 class="txt-color-gray fw-bold fs-6 mt-2 text-center text-success">
                    <b>{{row.complaints}}</b>
                  </h3>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Row-->

<!--begin::Tables Widget 9-->
              <div class="row g-0">
                <div class="col-sm rounded-4 me-7 mb-7 d-flex flex-column corner">
                  <div class="card mb-5 mb-xl-10">
                    <div class="row gy-5 g-xl-10">
                      <div class="col-xl-12">
                        <div class="card-header border-0 pt-5" style="">
                          <h3 class="card-title align-items-start flex-column">
                            <router-link to="/requests/list"  class="card-label fw-bolder fs-3 mb-1 text-success" custom v-slot="{ href, route, navigate, isActive }">
                          <span :active="isActive" :href="href" @click="navigate" @keypress.enter="navigate" role="link">
                             <span class="fas fa-wallet "></span>
                            <span class="ml-2 me-2">طلبات المعاملات النشطة</span>
                          </span>
                            </router-link>
                            <span class="text-muted mt-1 fw-bold fs-7">
                          <span> اجمالي عدد طلبات المعاملات النشطة : </span>
                          <span> ( {{row.active_requests}} ) </span>
                          <span> طلب </span>

                        </span>
                          </h3>

                        </div>

                        <!--end::Header-->
                        <!--begin::Body-->
                        <div class="card-body"  v-if="row.list.length > 0">
                          <div class="row mb-5"  v-if="row.list.length == 0">
                            <div class="no-record-block bg-light-warning text-warning">
                              <div class="row text-center">
                                <div class="col-lg-12 text-center">
                                  <label class="fw-bolder">لايوجد سجلات للعرض </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!--begin::Table container-->
                          <div class="table-responsive" >
                            <!--begin::Table-->
                            <table
                                    class="
                            table table-row-dashed table-row-gray-300
                            align-middle
                            gs-0
                            gy-4
                          "
                            >
                              <!--begin::Table head-->
                              <thead>
                              <tr class=" text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                                <th class="text-center">#</th>
                                <th class="text-center">رقم الطلب</th>
                                <th class="text-center">مقدم الطلب</th>
                                <th class="text-center">رقم هوية <br>مقدم الطلب</th>
                                <th class="text-center">تصنيف الخدمة</th>
                                <th class="text-center">الخدمة</th>
                                <!--<th class="text-center">حالة الطلب</th>-->
                                <th class="text-center">تاريخ الطلب</th>
                                <th class="text-center text-end">العمليات</th>
                              </tr>
                              </thead>
                              <!--end::Table head-->
                              <!--begin::Table body-->
                              <tbody>
                              <tr v-for="(x, index) in row.list" :key="index">
                                <td class="text-center">{{ (index+1) }}</td>
                                <td class="text-center">{{ x.request_no }}</td>
                                <td class="text-center">{{ x.applicant_name }}</td>
                                <td class="text-center">{{ x.applicant_id_no }}</td>
                                <td class="text-center">{{ x.service_category.name }}</td>
                                <td class="text-center">{{ x.service.name }}</td>
                                <!--<td class="text-center">{{ x.status.name }}</td>-->
                                <td class="text-center">{{ x.date }}</td>
                                <td class="text-center">
                                  <div class="btn-group" style="margin-left:10px !important;">
                                    <button type="button" @click="showRaw(index,x)" v-b-tooltip.hover title="عرض الطلب"
                                            class="btn btn-sm btn-success">
                                      <span class="fa fa-eye"></span>
                                    </button>
                                    <button type="button" @click="setStatus(index,x,3)" v-b-tooltip.hover title="انهاء الطلب"
                                            v-if="x.status_id == 1" class="btn btn-sm btn-warning">
                                      <span class="fa fa-hourglass-end"></span>
                                    </button>
                                    <button type="button" @click="setStatus(index,x,4)" v-b-tooltip.hover title="تعليق الطلب"
                                            v-if="x.status_id == 1" class="btn btn-sm" style="background: #a9a9a9;color: white;">
                                      <span class="fa fa-pause"></span>
                                    </button>
                                    <button type="button"  @click="setStatus(index,x,2)" v-b-tooltip.hover title="رفض الطلب"
                                            v-if="x.status_id == 1" class="btn btn-sm btn-danger">
                                      <span class="fas fa-times"></span>
                                    </button>
                                  </div>
                                </td>

                              </tr>

                              </tbody>
                              <!--end::Table body-->
                            </table>
                            <!--end::Table-->
                          </div>
                          <!--end::Table container-->
                        </div>
                        <!--begin::Body-->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--end::Tables Widget 9-->
            </div>
            <!--end::Stats-->

<!--start Modal -->
          <b-modal
              ref="form-modal"
              size="lg"
              hide-footer
              hide-header
              no-close-on-esc
              no-close-on-backdrop
              style="padding: 0px"
          >
            <!--begin::Card header-->
            <div class="card-header text-center mt-6 pb-3 border-bottom">
              <!--begin::Card title-->
              <div class="card-title text-success text-center">
                <h3 class="text-success">
                  {{ ModalTitle }}
                </h3>
              </div>
              <!--end::Card title-->
            </div>
            <!--end::Card header-->
            <!--begin::Card body-->
            <div class="card-body pt-4 text-right">
              <!--begin::Content-->
              <form id="form" name="form">
                <div class="row text-right px-1 px-lg-1 py-3 py-lg-3">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="row">
                        <div class="col-lg-3 fv-row">
                          <label class="col-form-label fw-bolder "> رقم الطلب</label><p class="form-control"> {{req.request_no}}</p>
                        </div>
                        <div class="col-lg-3 fv-row">
                          <label class="col-form-label fw-bolder "> حالة الطلب</label><p class="form-control"> {{ req.status.name }}</p>
                        </div>
                        <div class="col-lg-3 fv-row">
                          <label class="col-form-label fw-bolder "> تصنيف الخدمة </label><p class="form-control"> {{req.service_category.name}}</p>
                        </div>
                        <div class="col-lg-3 fv-row">
                          <label class="col-form-label fw-bolder ">الخدمة </label><p class="form-control"> {{req.service.name}}</p>
                        </div>
                        <div class="col-lg-3 fv-row">
                          <label class="col-form-label fw-bolder ">مقدم الطلب </label><p class="form-control"> {{req.applicant_name}}</p>
                        </div>
                        <div class="col-lg-3 fv-row">
                          <label class="col-form-label fw-bolder "> هوية مقدم الطلب </label><p class="form-control"> {{req.applicant_id_no}}</p>
                        </div>
                        <div class="col-lg-3 fv-row">
                          <label class="col-form-label fw-bolder ">رقم المبني </label><p class="form-control"> {{req.building_no}}</p>
                        </div>
                        <div class="col-lg-3 fv-row">
                          <label class="col-form-label fw-bolder ">رقم الشارع </label><p class="form-control"> {{req.street_no}}</p>
                        </div>
                        <div class="col-lg-3 fv-row">
                          <label class="col-form-label fw-bolder ">رقم حساب البلدية</label><p class="form-control"> {{req.municipality_account_no}}</p>
                        </div>
                        <div class="col-lg-3 fv-row">
                          <label class="col-form-label fw-bolder ">اسم المنتفع</label><p class="form-control"> {{req.beneficiary_name}}</p>
                        </div>
                        <div class="col-lg-3 fv-row">
                          <label class="col-form-label fw-bolder ">رقم هوية المنتفع </label><p class="form-control"> {{req.beneficiary_id_no}}</p>
                        </div>
                        <div class="col-lg-3 fv-row">
                          <label class="col-form-label fw-bolder ">اسم المالك</label><p class="form-control"> {{req.owner_name}}</p>
                        </div>
                        <div class="col-lg-3 fv-row">
                          <label class="col-form-label fw-bolder ">رقم هوية المالك </label><p class="form-control"> {{req.owner_id_no}}</p>
                        </div>
                        <div class="col-lg-12 fv-row">
                          <label class="col-form-label fw-bolder ">تفاصيل الطلب</label><p class="form-control"> {{req.details}}</p>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12 fv-row pt-3">
                        <h4 class="text-right text-blue">
                          ملاك الخدمة
                          <span class="text-dark">( عدد الملاك:  {{req.service_owners.length}}  )</span>
                        </h4>
                      </div>
                    </div>
                    <div class="row mb-6" v-if="req.service_owners.length > 0">
                      <div class="col-lg-12 fv-row pt-3">
                        <div class="table-responsive">
                          <table
                              class="table table-bordered align-middle fs-6 gy-5 table-row-dashed  table-hover table-checkable dataTable dtr-inline mt-5"
                          >
                            <!--begin::Table head-->
                            <thead>
                            <tr class="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                              <th class="text-center">#</th>
                              <th class="text-center">الاسم</th>
                              <th class="text-center">رقم الهوية</th>
                              <th class="text-center">رقم الجوال</th>
                            </tr>
                            </thead>
                            <tbody class="fw-bold ">
                            <tr v-for="(x, index) in req.service_owners" :key="index">
                              <td class="text-center " > {{index+1}}</td>
                              <td class="text-center " > {{x.name}}</td>
                              <td class="text-center " > {{x.id_no}}</td>
                              <td class="text-center " > {{x.mobile}}</td>
                            </tr>
                            </tbody>
                            <!--end::Table body-->
                          </table>
                        </div>
                      </div>
                    </div>

                    <div class="separator mx-1 my-3"></div>

                    <div class="row">
                      <div class="col-lg-12 fv-row pt-3">
                        <h4 class="text-right text-blue">
                          المرفقات
                          <span class="text-dark">( عدد المرفقات :  {{req.attachments.length}}  )</span>
                        </h4>
                      </div>
                    </div>
                    <div class="row mb-6" v-if="req.attachments.length > 0">
                      <div class="col-lg-12 fv-row pt-3">
                        <div class="table-responsive">
                          <table
                              class="table table-bordered align-middle fs-6 gy-5 table-row-dashed  table-hover table-checkable dataTable dtr-inline mt-5"
                          >
                            <!--begin::Table head-->
                            <thead>
                            <tr class="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                              <th class="text-center">#</th>
                              <th class="text-center">نوع المرفق</th>
                              <th class="text-center">ملاحظات</th>
                              <th class="text-center">تاريخ الارفاق</th>
                              <th class="text-center">العمليات</th>
                            </tr>
                            </thead>
                            <tbody class="fw-bold ">
                            <tr v-for="(x, index) in req.attachments" :key="index">
                              <td class="text-center " > {{index+1}}</td>
                              <td class="text-center " > {{x.attachment.name}}</td>
                              <td class="text-center " > {{x.notes}}</td>
                              <td class="text-center " >  {{onlyDate(x.created_at)}}</td>
                              <td class="text-center  text-end">
                                <button type="button" @click.prevent="download(x.id)"
                                        v-if="x.path != null "
                                        class="btn btn-sm btn-success "  v-b-tooltip.hover title="تنزيل المرفق">
                                  <span class="fa fa-download"></span>
                                </button>
                              </td>
                            </tr>
                            </tbody>
                            <!--end::Table body-->
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class=" col-lg-12 col-md-12 col-sm-12   pl-5 mt-6 action  border-top pt-4 " style="float: right">
                    <button type="button" class="btn btn-danger" @click="closeShowAction">اغلاق</button>
                  </div>
                </div>
              </form>
              <!--end::Content-->
            </div>
            <!--end::Card body-->
          </b-modal>
          <!--end Modal -->

          </div>
          <!--end::Body-->
        </div>
        <!--end::Card-->
      </div>
      <!--end::Container-->
    </div>
    <!--end::Post-->
  </div>
</template>

<script>
import ApiServices from "../core/services/api.services";

export default {
  name: "Dashboard",
  components: {
    // Toolbar ,
  },
  data() {
    return {
      list: [],
      row: {list :[], suggestions :0,complaints:0,active_requests:0,requests:0, transactions:0},
      loading: false,
      req: {id: null, request_no: ' ',service_category_id: ' ',service_category: {name:''},
            service_id: ' ',service: {name:''},applicant_id_no :'', applicant_name: ' ',building_no: ' ',
            street_no: ' ',municipality_account_no:' ',beneficiary_name:' ',
            beneficiary_id_no: ' ',owner_name: ' ',owner_id_no: ' ',details:' ',
            date:' ',status_id:' ', status : {name:''},attachments:[],service_owners:[]},
      ModalTitle: "",
    };
  },
  methods: {
 loadContent : function (){
      ApiServices.get('template/dashboard').then(
          response => {
            if(response.data){
              this.row = response.data ;
            }
          },
          error => {
            this.$toaster.error(error);
          });
   },
    
   showRaw(index ,row){
      this.curIndex = index;
      this.ModalTitle = "نموذج عرض بيانات الطلب";
      this.req = JSON.parse(JSON.stringify(row));
      this.$refs["form-modal"].show();
      this.loading = false;
    },
    closeShowAction() {
      this.req = {id: null, request_no: ' ',service_category_id: ' ',service_category: {name:''},
                  service_id: ' ',service: {name:''},applicant_id_no :'', applicant_name: ' ',building_no: ' ',
                  street_no: ' ',municipality_account_no:' ',beneficiary_name:' ',
                  beneficiary_id_no: ' ',owner_name: ' ',owner_id_no: ' ',details:' ',
                  date:' ',status_id:' ', status : {name:''},attachments:[],service_owners:[]};
      this.show = false;
      this.$refs["form-modal"].hide();
    },
    download (id) {
      ApiServices.download('requests/file/'+id).then(response => {
            var blob = new Blob([response.data], {type: response.headers['content-type']});
            var url = window.URL.createObjectURL(blob);
            window.open(url);
          },
          error => {
            this.$toaster.error(error);
          });

    },
    setStatus(index ,row,status_id){

      let msg ='';
      if(status_id == 3) msg = "هل تريد تأكيد انهاء الطلب";
      if(status_id == 4) msg = "هل تريد تأكيد تعليق الطلب";
      if(status_id == 2) msg = "هل تريد تأكيد رفض الطلب ";

      Swal.fire({
        title: msg +' ? ',
        showCancelButton: true,
        confirmButtonText: "تأكيد",
        confirmButtonColor: "#50CD89",
        cancelButtonText: "إلغاء",
        cancelButtonColor: "#F1416C",
      }).then((result) => {
        if (result.isConfirmed) {
          ApiServices.put('requests/setStatus/'+row.id,{status_id : status_id}).then(
              response => {
                if(response.data){
                  var res_ = response.data ;
                  if(res_.status){
                    this.$toaster.success (res_.message);
                    this.loadContent();
                  }else{
                    this.$toaster.error(res_.message);
                  }
                }
              },
              error => {
                this.$toaster.error(error); this.loading = false;
              });
        }
      });
    },
  },
  created: function () {
    this.loadContent();

  },

  computed: {},
};
</script>